import './App.css';

// Material UI package
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import React, { useState } from 'react';
import { Stack } from '@mui/material';

// typefaces
import "typeface-pacifico";
import "typeface-quicksand";

// Helmet allows us to put HTML stuff right into
// the html from react
import {Helmet} from 'react-helmet';

// Theme for the site
// Material design language defines some
// color shades by number 
const perilune_theme = createTheme({
  palette: {
    primary: {
      main: "#34403A", // Black Olive
    },
    secondary: {
      main: "#e8f7ef", // Black Olive 50
    },
    text: {
      primary: {
        //main: "#F6AE2D", // Hunyadi Yellow
        //main: "#839788", // Cambridge Blue
        main: "#A07178", // Mountbatten Pink
      },
      secondary: {
        main: grey[800]
      }
    }
  },
});

// styled site header
const SiteHeader = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.primary.main,
  backgroundColor: theme.palette.primary.main,
  fontFamily: 'Pacifico',
  fontSize: "32pt"
}));

// styled nav buttons
const NavButton = styled(Button)(({theme}) => ({
  textAlign: 'center',
  color: theme.palette.text.primary.main,
  backgroundColor: theme.palette.primary.main,
  fontFamily: 'Quicksand',
  fontWeight: '500',
  ":hover": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary.main }
}));

// Text-like button
const TextButton = styled(ButtonBase)(({theme}) => ({
  textAlign: 'left',
  color: theme.palette.text.primary.main,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '4pt',
  fontFamily: 'Quicksand',
  fontWeight: '500',
  fontSize: "12pt",
  ":hover": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary.main }
}));

// main view card
const MainViewCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.primary.main,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "4pt",
  width: '95%'
}));

// headshot card
const HeadShotCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary.main,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "10pt",
  width: "256px",
  height: "280px",
}));

// headshot

// bio card
const BioCard = styled(Box)(({theme}) => ({
  padding: theme.spacing(3),
  color: theme.palette.text.secondary.main,
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Quicksand',
  fontWeight: '500', 
}));

// contact info card
const ContactCard = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary.main,
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Quicksand',
  fontWeight: '500',
  minHeight: '175px'
}));

// resources card
const ResourcesCard = styled(Box)(({theme}) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary.main,
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Quicksand',
  fontWeight: '500'
}));

// google calendar card (?)

// Render "Perilune Wellness" in chosen font and size
// maybe someday some graphics?
function RenderPageHeader() {
  return (
    <React.Fragment>
      <SiteHeader theme={perilune_theme}>
        Perilune Wellness
      </SiteHeader>
    </React.Fragment>
  )
}

// render the navigation content and the main view
function RenderNavContent() {
  const [view_select, SetMainView] = useState("home");
  return(
    <React.Fragment>
      <Stack spacing={2} alignItems="center">
        {/* navi header */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <NavButton theme={perilune_theme} onClick={() => {SetMainView("home")}}>
              Home
            </NavButton>
          </Grid>
          <Grid item xs={4}>
            <NavButton theme={perilune_theme} onClick={() => {SetMainView("resources")}}>
              Resources
            </NavButton>
          </Grid>
          <Grid item xs={4}>
            <NavButton theme={perilune_theme} onClick={() => {SetMainView("contact")}}>
              Contact
            </NavButton>
          </Grid>
        </Grid>
        {/* main view */}
        <MainViewCard theme={perilune_theme}>
          {RenderMain(view_select, SetMainView)}
        </MainViewCard>
      </Stack>
    </React.Fragment>
  )

}

// render the main view
function RenderMain(view_select, SetMainView) {

  var content;

  switch(view_select)
  {
    case "home":
      content = HomeContent(SetMainView);
      break;
    case "resources":
      content = ResourcesContent();
      break;
    case "contact":
      content = ContactContent();
      break;
    default:
      content = 
      <React.Fragment>
        <h1>nothing here...</h1>
      </React.Fragment>
  }

  return content;

}

// render the home page content for the main view
function HomeContent(SetMainView) {
  return (
    <React.Fragment>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <HeadShotCard theme={perilune_theme}
          style={{
            margin:"auto",
          }}
          >
            <img className="Headshot-img" src="/headshot.jpg"
            style= {{
              width: 250,
              height: 250,
              borderBottomLeftRadius: 30,
              borderBottomRightRadius: 30,
              borderTopRightRadius: 30,
              borderTopLeftRadius: 30,
              overflow: "hidden",
              marginTop: "3px",
            }} alt="A headshot of Carly Kabitz smiling"
            />
            <p style={{fontSize: "14px", marginTop: "2px", marginBottom: "2px"}}><i>LICSW, MSW</i></p>
            <p style={{fontSize: "14px", marginTop: "2px"}}><i>License Number 28964</i></p>

          </HeadShotCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <BioCard theme={perilune_theme}
          elevation="2" 
          style={{
            textAlign: 'left'
          }}
          >
            <h1>Welcome!</h1>
            <p>
              Therapy is about transitioning from surviving to thriving, and I would love to be part of your journey!
            </p> 
            <p>
              Together, we will build new coping skills, challenge unhelpful thinking patterns, and improve your mental wellness. 
              We’ll build on the skills that you already have, and identify and adjust what isn’t working for you. 
              Throughout our sessions, I empower my clients to define their own goals and to use their strengths to reach those goals.
            </p>
            <p>
              Finding the right therapist for you can be a challenge and I believe that a strong connection between therapists and clients is the most important element of therapy. 
              Because of this, I offer a free 15 minute consultation for you to have the opportunity to get to know me more and ask any questions you may have to ensure that we are a good fit.
            </p>
            <p>
              I specialize in treatments for:
            </p> 
            <ul>
              <li>Obsessive Compulsive Disorder (OCD)</li>
              <li>Anxiety disorders</li>
              <li>Phobias</li>
              <li>Trauma</li>
              <li>Depression</li>
            </ul>
            <p>
              I use the following treatment approaches:
            </p>
            <ul>
              <li>Exposure and Response Prevention (ERP)</li>
              <li>Prolonged Exposure (PE)</li>
              <li>Eye Movement Desensitization and Reprocessing (EMDR)</li>
              <li>Dialectical Behavioral Therapy (DBT)</li>
              <li>Cognitive Behavioral Therapy (CBT)</li>
              <li>Strengths-focused</li>
              <li>Person-centered</li>
              <li>Trauma Informed</li>
              <li>Motivational Interviewing</li>
            </ul>
            <p>
              I am currently able to work with the following insurance providers:
            </p>
            <ul>
              <li>Medica</li>
              <li>Optum</li>
              <li>United Healthcare</li>
              <li>UMR</li>
              <li>Blue Cross Blue Shield</li>
              <li>MNFire</li>
            </ul>
            <p>
              Additionally, I accept private pay.
            </p>
            <p>
              I am a board approved supervisor for both BSW and LGSW-holders.
              If you are interested in supervision, 
              <TextButton theme={perilune_theme} onClick={() => {SetMainView("contact")}}>contact me for availability.</TextButton>
            </p>
          </BioCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

// render the resources page content for the main view
function ResourcesContent() {
  return (
    <React.Fragment>
      <ResourcesCard theme={perilune_theme}>
        <h1>Professional resources available to help during a crisis:</h1>
        <p>If it’s an emergency, call 911 and ask for the county on-call behavioral health crisis worker or mobile crisis.</p> 
        <p>Most counties have a licensed social worker or therapist that will come to your home to talk with you and determine if hospitalization is needed or if you can remain safe at home/another location with a safety plan.</p>
        
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <ContactCard theme={perilune_theme} elevation={4}>
              <p style={{
                marginBottom:"65px"
              }}>MN Crisis Text Line: Text "HOME" to 741741</p>
              <p>National Suicide Hotline: 1-800-273-8255 (press 1 for help for veterans)</p>
            </ContactCard>
          </Grid>
          <Grid item md={6} xs={12}>
            <ContactCard theme={perilune_theme} elevation={4}>
              <p>LGBTQ Crisis Resources:</p>
                <p>Trevor Project Youth Crisis: 866-488-7386</p>
                <p>LGBTQ Crisis Text Line: Text GO to 741741</p>
                <p>Transgender Lifeline: 877-565-8860</p>
            </ContactCard>
          </Grid>
        </Grid>
      </ResourcesCard>

    </React.Fragment>
  );
}

// render the contact page content for the main view
function ContactContent() {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ContactCard 
            theme={perilune_theme}
            elevation={4}>
            <h1 style={{
              marginBottom:"55px"
            }}>Email</h1>
            <a href="mailto:Carly@PeriluneWellness.com">Carly@PeriluneWellness.com</a>
          </ContactCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactCard 
            theme={perilune_theme}
            elevation={4}>
            <h1 style={{
              marginBottom:"55px"
            }}>Phone</h1>
            <a href="tel:651-300-9897">(651) 300-9897</a>
          </ContactCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function App() {
  var bg_color_text = "body {background-color: " + perilune_theme.palette.primary.main
  return (
    <div className="App">
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <Helmet>
        <title>Perilune Wellness</title>
        <style>{bg_color_text}</style>
      </Helmet>

      <Box>
        <Stack spacing={2}>
          {RenderPageHeader()}
          {/* Render navigation and main view */}
          {RenderNavContent()}
          <Box>

          </Box>
        </Stack>
      </Box>
     
    </div>
  );
}

export default App;
